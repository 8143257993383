export const EMAIL: string = "EMAIL";
export const PASS_WORD: string = "PASS_WORD";
export const FORGET_PASSWORD: string = "F_PASS";
export const USER_NAME: string = "USER_NAME";
export const ADMINISTRATOR: string = "ADMINISTRATOR";
export const ORGANIZATIONS: string = "ORGANIZATIONS";
export const ACTIVE_RESERVATIONS: string = "ACTIVE_RESERVATIONS";
export const UPCOMING_RESERVATIONS: string = "UPCOMING_RESERVATIONS";
export const RESERVATION_IN_PROGRESS: string = "RESERVATION_IN_PROGRESS";
export const CANCELLATIONS_IN_THE_LAST_6MONTHS: string =
  "CANCELLATIONS_IN_THE_LAST_6MONTHS";
export const FAILED_PAYMENT: string = "FAILED_PAYMENT";
export const INCOMPLETE_CUSTOMER_PROFILE: string =
  "INCOMPLETE_CUSTOMER_PROFILE";
export const TOTAL_SEARCHES_IN_THE_LAST_6MONTHS: string =
  "TOTAL_SEARCHES_IN_THE_LAST_6MONTHS";
export const RESERVATIONS: string = "RESERVATIONS";
export const DRIVER_NAME: string = "DRIVER_NAME";
export const COUNT: string = "COUNT";
export const EMAIL_ID: string = "EMAIL_ID";
export const PHONE: string = "PHONE";
export const REGISTRATION_DATE: string = "REGISTRATION_DATE";
export const LAST_RESERVATION_DATE: string = "LAST_RESERVATION_DATE";
export const STATUS: string = "STATUS";
export const RESERVATION_ID: string = "RESERVATION_ID";
export const RESERVATION_CODE: string = "RESERVATION_CODE";
export const RES_CODE: string = "RES_CODE";
export const COMPANY_NAME: string = "COMPANY_NAME";
export const PARKING_LOT: string = "PARKING_LOT";
export const PARKING_STATUS: string = "PARKING_STATUS";
export const RESERVED_BY: string = "RESERVED_BY";
export const DURATION: string = "DURATION";
export const DURATION_PRICING: string = "DURATION_PRICING";
export const PRICING: string = "PRICING";
export const CREATED_BY: string = "CREATED_BY";
export const CREATED_ON: string = "CREATED_ON";
export const LAST_UPDATED_ON: string = "LAST_UPDATED_ON";
export const LAST_UPDATED_BY: string = "LAST_UPDATED_BY";
export const FROM_LOCATION: string = "FROM_LOCATION";
export const TO_LOCATION: string = "TO_LOCATION";
export const SEARCHED_ON: string = "SEARCHED_ON";
export const DASHBOARD: string = "DASHBOARD";
export const MAP_DASHBOARD: string = "MAP_DASHBOARD";
export const FINPARK_ADMIN_ALL_RIGHTS_RESERVED: string =
  "FINPARK_ADMIN_ALL_RIGHTS_RESERVED-";
export const INVOICE_DASHBOARD: string = "INVOICE_DASHBOARD";
export const ADD_ORGANIZATION: string = "ADD_ORGANIZATION";
export const ADDRESS: string = "ADDRESS";
export const COUNTRY: string = "COUNTRY";
export const DOT: string = "DOT";
export const CREATED_DATE: string = "CREATED_DATE";
export const SUB_COUNT: string = "SUB_COUNT";
export const UPDATED_BY: string = "UPDATED_BY";
export const UPDATED_ON: string = "UPDATED_ON";
export const ORGANIZATION_NAME: string = "ORGANIZATION_NAME";
export const ADDRESS_LANE1: string = "ADDRESS_LANE1";
export const CITY: string = "CITY";
export const STATE: string = "STATE";
export const ZIP_CODE: string = "ZIP_CODE";
export const FIRST_NAME: string = "FIRST_NAME";
export const LAST_NAME: string = "LAST_NAME";
export const ORGANIZATION_DETAILS: string = "ORGANIZATION_DETAILS";
export const SUBSCRIPTION_DETAILS: string = "SUBSCRIPTION_DETAILS";
export const BANK_DETAILS: string = "BANK_DETAILS";
export const PAYMENT_DETAILS: string = "PAYMENT_DETAILS";
export const USERS: string = "USERS";
export const GENERAL_CONFIGURATION: string = "GENERAL_CONFIGURATION";
export const ROLE: string = "ROLE";
export const AMENITIES: string = "AMENITIES";
export const IMAGE: string = "IMAGE";
export const AMENITY: string = "AMENITY";
export const EDITED_BY: string = "EDITED_BY";
export const EDITED_ON: string = "EDITED_ON";
export const PARKING_LOT_NAME: string = "PARKING_LOT_NAME";
export const PARKING_LOT_ID: string = "PARKING_LOT_ID";
export const PARKING_LOTS: string = "PARKING_LOTS";
export const RATING: string = "RATING";
export const PARKING_LOT_DETAILS: string = "PARKING_LOT_DETAILS";
export const PARKING_LOT_INFO: string = "PARKING_LOT_INFO";
export const PARKING_SPACE_TYPE: string = "PARKING_SPACE_TYPE";
export const GENERAL_DETAILS: string = "GENERAL_DETAILS";
export const CONTACT: string = "CONTACT";
export const SELECT_COMPANY_NAME: string = "SELECT_COMPANY_NAME";
export const SPACE_TYPE: string = "SPACE_TYPE";
export const SLOT_COUNT: string = "SLOT_COUNT";
export const ADD_PARKING_LOT: string = "ADD_PARKING_LOT";
export const ADD_PRICING: string = "ADD_PRICING";
export const ADD_INVITE: string = "ADD_INVITE";
export const SEND_INVITE: string = "SEND_INVITE";
export const TERM: string = "TERM";
export const HOURLY_RATE: string = "HOURLY_RATE";
export const Add_AMENITIES: string = "Add_AMENITIES";
export const CHECKED: string = "CHECKED";
export const NEARBY_AMENITIES: string = "NEARBY_AMENITIES";
export const ADD_NEARBY_AMENITY: string = "ADD_NEARBY_AMENITY";
export const ADD_CONTACT: string = "ADD_CONTACT";
export const DRIVERS: string = "DRIVERS";
export const ADD_USERS: string = "ADD_USERS";
export const ADD: string = "ADD";
export const REPORTS: string = "REPORTS";
export const CANCEL: string = "CANCEL";
export const SAVE: string = "SAVE";
export const PAYNSUBSCRIBE: string = "Subscribe";
export const ADD_CC: string = "Add Credit card";
export const PAY: string = "PAY";
export const CLOSE: string = "CLOSE";
export const PROFILE: string = "PROFILE";
export const LOGOUT: string = "LOGOUT";
export const SWITCH_USER: string = "SWITCH_USER";
export const MAP: string = "MAP";
export const FINANCIAL_INSTITUTION_NAME: string = "FINANCIAL_INSTITUTION_NAME";
export const ADDRESS_LANE2: string = "ADDRESS_LANE2";
export const CURRENCY: string = "CURRENCY";
export const ACCOUNT: string = "ACCOUNT";
export const RECIPIENT_NAME: string = "RECIPIENT_NAME";
export const ABA_ROUTING_NUMBER: string = "ABA_ROUTING_NUMBER";
export const TYPE: string = "TYPE";
export const SEND_INVITATION: string = "SEND_INVITATION";
export const SELECT_A_STATUS: string = "SELECT_A_STATUS";
export const COMPANY_DASHBOARD: string = "COMPANY_DASHBOARD";
export const LOGIN: string = "LOGIN";
export const CHANGE_PASSWORD: string = "CHANGE_PASSWORD";
export const OLD_PASSWORD: string = "OLD_PASSWORD";
export const NEW_PASSWORD: string = "NEW_PASSWORD";
export const CONFIRM_NEW_PASSWORD: string = "CONFIRM_NEW_PASSWORD";
export const SWITCH_ORGANIZATION: string = "SWITCH_ORGANIZATION";
export const SELECT_AN_ORGANIZATION: string = "SELECT_AN_ORGANIZATION";
export const SELECT_ORGANIZATION: string = "SELECT_ORGANIZATION";
export const SWITCH: string = "SWITCH";
export const SALES_AGENT_COMMISION_PERCENTAGE: string =
  "SALES_AGENT_COMMISION_PERCENTAGE";
export const SALES_AGENT_NAME: string = "SALES_AGENT_NAME";
export const SALES_AGENT_MOBILE_NUMBER: string = "SALES_AGENT_MOBILE_NUMBER";
export const SALES_AGENT_EMAIL: string = "SALES_AGENT_EMAIL";
export const I_AUTHORIZE_VHUB_TO_SEND_ME_PROMOTINAL_EMAIL: string =
  "I_AUTHORIZE_VHUB_TO_SEND_ME_PROMOTINAL_EMAIL";
export const FINPARKS_PLATFORM_FEE: string = "FINPARKS_PLATFORM_FEE";
export const CONSOLIDATE_RESERVATION_CHARGES_WILL_BE_PAID_ON: string =
  "CONSOLIDATE_RESERVATION_CHARGES_WILL_BE_PAID_ON";
export const COMPANY: string = "COMPANY";
export const GENERIC_PARKING_SLOTS: string = "GENERIC_PARKING_SLOTS";
export const SECURED: string = "SECURED";
export const DESCRIPTION: string = "DESCRIPTION";
export const PHOTO: string = "PHOTO";
export const RATINGS: string = "RATINGS";
export const SET_PASSWORD: string = "SET_PASSWORD";
export const CONFIRM_PASSWORD: string = "CONFIRM_PASSWORD";
export const PROMATIONAL_MAILS: string = "PROMATIONAL_MAILS";
export const I_ACCEPT_THE_TERMS_CONDITIONS: string =
  "I_ACCEPT_THE_TERMS_CONDITIONS";
export const SUBMIT: string = "SUBMIT";
export const MY_PROFILE: string = "MY_PROFILE";
export const FORGOT_USERNAME_PASSWORD: string = "FORGOT_USERNAME_PASSWORD";
export const COLUMN_SORT: string = "COLUMN_SORT";
export const COLUMN_HEADING: string = "COLUMN_HEADING";
export const DESC: string = "DESC";
export const OPERATION: string = "OPERATION";
export const PARKING_CODE: string = "PARKING_CODE";
export const TIME_ZONE: string = "TIME_ZONE";
export const MAXIMUN_PARKING_SLOTS: string = "MAXIMUN_PARKING_SLOTS";
export const NEARBY_AMENTIES: string = "NEARBY_AMENTIES";
export const ENTRY_INSTRUCTIONS: string = "ENTRY_INSTRUCTIONS";
export const EXIT_INSTRUCTIONS: string = "EXIT_INSTRUCTIONS";
export const CONDITION_RESTRICTION: string = "CONDITION_RESTRICTION";
export const DRIVER: string = "DRIVER";
export const FROM_DATE: string = "FROM_DATE";
export const TO_DATE: string = "TO_DATE";
export const FROM: string = "FROM";
export const TO: string = "TO";
export const START_DATE: string = "START_DATE";
export const END_DATE: string = "END_DATE";
export const COMPLETED: string = "COMPLETED";
export const ACTIVE: string = "ACTIVE";
export const IN_ACTIVE: string = "IN_ACTIVE";
export const CUSTOMER_NAME: string = "CUSTOMER_NAME";
export const PARKING_LOT_OWNER_DETAILS: string = "PARKING_LOT_OWNER_DETAILS";
export const CONTACT_PERSON: string = "CONTACT_PERSON";
export const CONTACT_NUMBER: string = "CONTACT_NUMBER";
export const DRIVER_DETAILS: string = "DRIVER_DETAILS";
export const RESERVATION_DETAILS: string = "RESERVATION_DETAILS";
export const ACTIVATION_DETAILS: string = "ACTIVATION_DETAILS";
export const PARKING_LOT_ADDRESS: string = "PARKING_LOT_ADDRESS";
export const LINK_ITEM: string = "LINK_ITEM";
export const DEFAULT_PRICE: string = "DEFAULT_PRICE";
export const SERVICE_FEE: string = "SERVICE_FEE";
export const TIME: string = "TIME";
export const AMOUNT: string = "AMOUNT";
export const OF_EVERY_MONTH: string = "OF_EVERY_MONTH";
export const INVITED: string = "INVITED";
export const RESEND: string = "RESEND";
export const TOTAL_PARKING_LOTS_SPACES: string = "TOTAL_PARKING_LOTS_SPACES";
export const INCOMPLETE_PARKING_LOT_PROFILE: string =
  "INCOMPLETE_PARKING_LOT_PROFILE";
export const PARKING_TYPE: string = "PARKING_TYPE";
export const COLUMN_HIDE_SHOW: string = "COLUMN_HIDE_SHOW";
export const VISIBLE: string = "VISIBLE";
export const PARKING_SLOTS: string = "PARKING_SLOTS";
export const IS_SECURED: string = "IS_SECURED";
export const SELECT: string = "SELECT";
export const IS_DEFAULT: string = "IS_DEFAULT";
export const SEARCH: string = "SEARCH";
export const RESERVATION: string = "RESERVATION";
export const ACTIVATION_DATE: string = "ACTIVATION_DATE";
export const TOTAL_RATE: string = "TOTAL_RATE";
export const RESERVATION_CREATION: string = "RESERVATION_CREATION";
export const RESERVATION_START_DATE: string = "RESERVATION_START_DATE";
export const RESERVATION_END_DATE: string = "RESERVATION_END_DATE";
export const BILL_DETAILS: string = "BILL_DETAILS";
export const INVOICES_STATEMENT_OF_INCOME: string =
  "INVOICES_STATEMENT_OF_INCOME";
export const INVOICESCC: string = "INVOICES";
export const INVOICEID: string = "Invoice ID";
export const DATE: string = "DATE";
export const DOCUMENT_NUMBER: string = "DOCUMENT_NUMBER";
export const DOCUMENT_TYPE: string = "DOCUMENT_TYPE";
export const COMPANY_PROFILE: string = "COMPANY_PROFILE";
export const COMPANY_DETAILS: string = "COMPANY_DETAILS";
export const PARKING_LOT_PRICING: string = "PARKING_LOT_PRICING";
export const ADD_AMENITY: string = "ADD_AMENITY";
export const BACK_ADMIN: string = "BACK_ADMIN";
export const ENTER_YOUR_EMAIL: string = "ENTER_YOUR_EMAIL";
export const YOUR_ACCOUNT: string = "YOUR_ACCOUNT";
export const SEND: string = "SEND";
export const ASSIGN_PARKING_LOTS: string = "ASSIGN_PARKING_LOTS";
export const SELECT_ALL: string = "SELECT_ALL";
export const ASSIGN: string = "ASSIGN";
export const EDIT_PRICING: string = "EDIT_PRICING";
export const UPDATE: string = "UPDATE";
export const WIFI: string = "WIFI";
export const SHOWER: string = "SHOWER";
export const RESTARENT: string = "RESTARENT";
export const PARKING: string = "PARKING";
export const GAS_STATION: string = "GAS_STATION";
export const TRAILER: string = "TRAILER";
export const TRUCK: string = "TRUCK";
export const REPAIR: string = "REPAIR";
export const DRUG_STORE: string = "DRUG_STORE";
export const EDIT_AMENITY: string = "EDIT_AMENITY";
export const EDIT_USERS: string = "EDIT_USERS";
export const INVOICE_COUNT: string = "INVOICE_COUNT";
export const INVOICE: string = "INVOICE";
export const INVOICE_DATE: string = "INVOICE_DATE";
export const ACTION: string = "ACTION";
export const SERVICE_ITEM: string = "SERVICE_ITEM";
export const TAX: string = "TAX";
export const RENT_PER_HR: string = "RENT_PER_HR";
export const PALTFORM_PERCENTAGE: string = "PALTFORM_PERCENTAGE";
export const PLATFORM_FEE: string = "PLATFORM_FEE";
export const CR_DR: string = "CR_DR";
export const TOTAL_AMOUNT: string = "TOTAL_AMOUNT";
export const TOTAL: string = "TOTAL";
export const PLEASE_ENTERED_THE_REGISTRATION_EMAIL_ID: string =
  "PLEASE_ENTERED_THE_REGISTRATION_EMAIL_ID";
export const TRANSACTION_ID: string = "TRANSACTION_ID";
export const TRANSACTION_AMOUNT: string = "TRANSACTION_AMOUNT";
export const ACTUAL_TRANSACTION_DATE: string = "ACTUAL_TRANSACTION_DATE";
export const TRANSACTION_INITIATION_DATE: string =
  "TRANSACTION_INITIATION_DATE";
export const RESERVATION_CODE_LIST: string = "RESERVATION_CODE_LIST";
export const INVOICE_CODE_LIST: string = "INVOICE_CODE_LIST";
export const CREDIT_CARD_NUMBER: string = "CREDIT_CARD_NUMBER";
export const CARD_NUMBER: string = "CARD_NUMBER";
export const ACCOUNT_RECEIVABLE_ID: string = "ACCOUNT_RECEIVABLE_ID";
export const DETAILS: string = "DETAILS";
export const RENT_RER_HOUR: string = "RENT_RER_HOUR";
export const TOTAL_TAX: string = "TOTAL_TAX";
export const BILLING_AMOUNT: string = "BILLING_AMOUNT";
export const PAYMENT_SUCCESS: string = "PAYMENT_SUCCESS";
export const JURISDICATION_TYPE: string = "JURISDICATION_TYPE";
export const STATE_ASSIGNED_NO: string = "STATE_ASSIGNED_NO";
export const JURIS_NAME: string = "JURIS_NAME";
export const RATE: string = "RATE";
export const PAYMENT_STATUS: string = "PAYMENT_STATUS";
export const SOI_DASHBOARD: string = "SOI_DASHBOARD";
export const SOI_COUNT: string = "SOI_COUNT";
export const SOI_ID: string = "SOI_ID";
export const SOI_START_DATE: string = "SOI_START_DATE";
export const SOI_END_DATE: string = "SOI_END_DATE";
export const SWIFT_CODE: string = "SWIFT_CODE";
export const FINANCIAL_INSTITUTIONNUMBER: string =
  "FINANCIAL_INSTITUTIONNUMBER";
export const TRANSIT_NUMBER: string = "TRANSIT_NUMBER";
export const INVOICE_ID: string = "INVOICE_ID";
export const INVOICE_START_DATE: string = "INVOICE_START_DATE";
export const INVOICE_END_DATE: string = "INVOICE_END_DATE";
export const GATE_ACCESS_CODE: string = "GATE_ACCESS_CODE";
export const PREFERRED_LANG: string = "PREFERRED_LANG";
export const GENERAL_SPACES: string = "GENERAL_SPACES";
export const AVAILABLE: string = "AVAILABLE";
export const BOOKED: string = "BOOKED";
export const BOOK: string = "BOOK";
export const TOTAL_PARKING_LOTS: string = "TOTAL_PARKING_LOTS";
export const PARKING_ACCESS_CODE: string = "PARKING_ACCESS_CODE";
export const ADDED_DATE: string = "ADDED_DATE";
export const EXPIRE_DATE: string = "EXPIRE_DATE";
export const DOC_EXPIRE_DATE: string = "DOC_EXPIRE_DATE";
export const QR_CODE: string = "QR_CODE";
export const ACCESS_CODE: string = "ACCESS_CODE";
export const TRUCK_DETAILS: string = "TRUCK_DETAILS";
export const TRUCK_NAME: string = "TRUCK_NAME";
export const MODEL: string = "MODEL";
export const COLOUR: string = "COLOUR";
export const LICENSE_PLATE: string = "LICENSE_PLATE";
export const YEAR: string = "YEAR";
export const CONFIRM: string = "CONFIRM";
export const CONFIRM_MSG: string = "CONFIRM_MSG";
export const CONFIRM_MSG_DEL: string = "CONFIRM_MSG_DEL";
export const TRUCK_DETAILS_REQUIRED: string = "TRUCK_DETAILS_REQUIRED";
export const ORGANIZATION: string = "ORGANIZATION";
export const CREATION_DATE: string = "CREATION_DATE";
export const DISCOUNT_CODE: string = "DISCOUNT_CODE";
export const SUBSCRIPTION_AMOUNT: string = "SUBSCRIPTION_AMOUNT";
export const ADD_DISCOUNT_CODE: string = "ADD_DISCOUNT_CODE";
export const ADD_DISCOUNT: string = "ADD_DISCOUNT";
export const DISCOUNT_CODE_ID: string = "DISCOUNT_CODE_ID";
export const VALIDITY: string = "VALIDITY";
export const LIMIT: string = "LIMIT";
export const DISCOUNT_PERCENTAGE: string = "DISCOUNT_PERCENTAGE";
export const FIXED_DISCOUNT: string = "FIXED_DISCOUNT";
export const CREATED: string = "CREATED";
export const LAST_UPDATED: string = "LAST_UPDATED";
export const TRANSACTION_COUNT: string = "TRANSACTION_COUNT";
export const BALANCE_REMAINING: string = "BALANCE_REMAINING";
export const UPDATION_DATE: string = "UPDATION_DATE";
export const DELETE: string = "DELETE";
export const EDIT: string = "EDIT";
export const DISCOUNT_TYPE: string = "DISCOUNT_TYPE";
export const DISCOUNT_CODE_NAME: string = "DISCOUNT_CODE_NAME";
export const CUSTOM_DISCOUNT_CODE: string = "CUSTOM_DISCOUNT_CODE";
export const PERCENTAGE: string = "PERCENTAGE";
export const MAX_LIMIT_PER_CODE: string = "MAX_LIMIT_PER_CODE";
export const TOTAL_VALUE: string = "TOTAL_VALUE";
export const VALID_COUNTRY: string = "VALID_COUNTRY";
export const PROVINCE: string = "PROVINCE";
export const NOTES: string = "NOTES";
export const NOTE: string = "NOTE";
export const CODE: string = "CODE";
export const AMOUNT_PERCENTAGE: string = "AMOUNT_PERCENTAGE";
export const USAGE: string = "USAGE";
export const MAX_CAP: string = "MAX_CAP";
export const MAXIMUM_USAGE: string = "MAXIMUM_USAGE";
export const DELETE_DISCOUNT_MSG: string = "DELETE_DISCOUNT_MSG";
export const INSTRUCTIONS: string = "INSTRUCTIONS";
export const MOBILE_NUMBER: string = "MOBILE_NUMBER";
export const REDEEMED: string = "REDEEMED";
export const DISCOUNT_INVOICE: string = "DISCOUNT_INVOICE";
export const REMAINING_USAGE: string = "REMAINING_USAGE";
export const ROWSPERPAGE: number = 10;
export const DATASIZE: number = 10;
export const POBOX_NO: string = "POBOX_NO"; // kalyani
export const CONFIRMATION: string = "CONFIRMATION";
export const ALL: string = "ALL";
export const NEW_DRIVER: string = "NEW_DRIVER";
export const OWNER_COMPANY: string = "OWNER_COMPANY";
export const PAID_BY: string = "PAID_BY";
export const PAID: string = "PAID";
export const PHONE_1: string = "PHONE_1";
export const SEND_LINK: string = "SEND_LINK";
export const ADD_DRIVER: string = "ADD_DRIVER";
export const EDIT_DRIVER: string = "EDIT_DRIVER";
export const EDIT_DISCOUNT_CODE: string = " EDIT_DISCOUNT_CODE";
export const DOWNLOAD_ALL: string = "DOWNLOAD_ALL";
export const EMPLOYEE_ID: string = "EMPLOYEE_ID";
export const CREATION: string = "CREATION";
export const UPDATED: string = "UPDATED";
export const EMAIL_ID1: string = "EMAIL_ID1";
export const DRIVER_TYPE: string = "DRIVER_TYPE";
export const DRIVER_PHONE_NO: string = "DRIVER_PHONE_NO";
export const ASSET_ID: string = "ASSET_ID";
export const EQUIPMENT_TYPE: string = "EQUIPMENT_TYPE";
export const CONFIRM_MSG_BANK_VERIFY: string = "CONFIRM_MSG_BANK_VERIFY";
export const VERIFY: string = "VERIFY";
export const INVOICES: string = "INVOICES";
export const ADD_PAYMENT: string = "ADD_PAYMENT";
export const CONFIRM_MSG_DEL_CC: string = "CONFIRM_MSG_DEL_CC";
export const FINPARK_RESERVE: string = "FINPARK_RESERVE";
export const PUSH_NOTIFICATION: string = "PUSH_NOTIFICATION";
export const PAYMENT_BY: string = "PAYMENT_BY";
export const ASSETS: string = "ASSETS";
export const ASSET: string = "ASSET";
export const ASSET_NAME: string = "ASSET_NAME";
export const ASSET_TYPE: string = "ASSET_TYPE";
export const GROUP_ID: string = "GROUP_ID";
export const PHONE1: string = "PHONE1";
export const BUSINESSWEBSITE = "BUSINESSWEBSITE";
export const BUSINESSNAME = "BUSINESSNAME";
export const ADD_BANK = "ADD_BANK";
export const COMPANYREGNUMBER = "COMPANYREGNUMBER";
export const SERIAL_NO: string = "SERIAL_NO";
export const PRICE: string = "PRICE";
export const RECURRING: string = "RECURRING";
export const ORGANIZATION_TYPE: string = "ORGANIZATION_TYPE";
export const REVENUE: string = "REVENUE";
export const RECONCILIATION: string = "RECONCILIATION";
export const RESERVATION_STATUS: string = "RESERVATION_STATUS";
export const INVOICE_AMOUNT: string = "INVOICE_AMOUNT";
export const SHARELINK: string = "SHARELINK";
export const SEND_PAYMENT_LINK: string = "SEND_PAYMENT_LINK";
export const ASSET_NUMBER: string = "ASSET_NUMBER";
export const BANK_SELCTION_WARNING: string =
  "The bank payment transaction will take 3 to 4 days to complete.";
export const ORGANIZATION_COUNT: string = "ORGANIZATION_COUNT";
export const TRANSACTION_CODE: string = "TRANSACTION_CODE";
export const AMOUNT_PAID: string = "AMOUNT_PAID";
export const SEQUANCE: string = "SEQUANCE";
export const CARD_ID: string = "CARD_ID";
export const PAYMENT_MODE: string = "PAYMENT_MODE";
export const SUBSCRIPTION_TRANS_ID: string = "SUBSCRIPTION_TRANS_ID";
export const RELATION_ID: string = "RELATION_ID";
export const BANK_ID: string = "BANK_ID";
export const START_DATE_TIME: string = "START_DATE_TIME";
export const END_DATE_TIME: string = "END_DATE_TIME";
export const LAST_UPDATED_DATE: string = "LAST_UPDATED_DATE";
export const ORG_ID: string = "ORG_ID";
export const SUBSCRIPTIONS: string = "SUBSCRIPTIONS";
export const SUBSCRIPTION_STATUS: string = "SUBSCRIPTION_STATUS";
export const EXPIRATION_DATE: string = "EXPIRATION_DATE";
export const API_HUB: string = "API_HUB";
export const API_CONFIGURATION: string = "API_CONFIGURATION";
export const SUBSCRIPTION_TYPE: string = "SUBSCRIPTION_TYPE";
export const SUBSCRIPTION_NAME: string = "SUBSCRIPTION_NAME";
export const CONFIRM_MSG_DRIVER: string = "CONFIRM_MSG_DRIVER";
export const CONFIRM_MSG_DISSOCIATE_DRIVER: string =
  "CONFIRM_MSG_DISSOCIATE_DRIVER";
export const VALIDATE: string = "VALIDATE";
export const BACK: string = "BACK";
export const CONTINUE: string = "CONTINUE";
export const REVENUE_FORECAST: string = "REVENUE_FORECAST";
export const UNPAID_INVOICES: string = "UNPAID_INVOICES";
export const CARRIER_BROKER: string = "CARRIER_BROKER";
export const STATISTICS: string = "STATISTICS";
export const CAPTCHA: string = "CAPTCHA";
export const DRIVER_ID: string = "DRIVER_ID";
export const DEVICE_ID: string = "DEVICE_ID";
export const EXTENSION_COUNT: string = "EXTENSION_COUNT";
export const ASSOCIATION_COUNT: string = "ASSOCIATION_COUNT";
export const EXTEND = "EXTEND";
export const FUTURE_RECURRENCE: string = "FUTURE_RECURRENCE";
export const TOTAL_INVOICES: string = "TOTAL_INVOICES";
export const PAID_INVOICES: string = "PAID_INVOICES";
export const PENDING_INVOICES: string = "PENDING_INVOICES";
export const FAILED_INVOICES: string = "FAILED_INVOICES";
export const EXPIRED_INVOICES: string = "EXPIRED_INVOICES";
export const CANCELLED_INVOICES: string = "CANCELLED_INVOICES";
export const PARKING_LOT_COUNT: string = "PARKING_LOT_COUNT";
export const CARRIER_NETWORK: string = "CARRIER_NETWORK";
export const CARRIER_ORGANIZATION: string = "CARRIER_ORGANIZATION";
export const ADD_CARRIER: string = "ADD_CARRIER";
export const PAYMENTS: string = "PAYMENTS";
export const PAYMENT: string = "PAYMENT";
export const NEXT: string = "NEXT";
export const SUBSCRIPTION_INVOICE: string = "SUBSCRIPTION_INVOICE";
export const PROGRESS: string = "PROGRESS";
export const DISCOUNT: string = "DISCOUNT";
export const RESERVATION_COUNT: string = "RESERVATION_COUNT";
export const SUBSCRIPTION_CODE: string = "SUBSCRIPTION_CODE";
export const LICENSE_NO: string = "LICENSE_NO";
export const ASSOCIATED_COMPANY: string = "ASSOCIATED_COMPANY";
export const MAKE_MODAL: string = "MAKE_MODAL";
export const MAKE_YEAR: string = "MAKE_YEAR";
export const COLOR: string = "COLOR";
export const NUMBER_OF_AXLES: string = "NUMBER_OF_AXLES";
export const LENGTH_OF_TRAILER: string = "LENGTH_OF_TRAILER";
export const ADD_ASSET: string = "ADD_ASSET";
export const EDIT_ASSET: string = "EDIT_ASSET";
export const BULK_IMPORT: string = "BULK_IMPORT";
export const BOOKING_FAILED: string = "BOOKING_FAILED";
export const CANCELLED: string = "CANCELLED";
export const EXPIRED: string = "EXPIRED";
export const FAILED: string = "FAILED";
export const PAYMENT_PENDING: string = "PAYMENT_PENDING";
export const PAYMENT_INITIATED: string = "PAYMENT_INITIATED";
export const REMOVED: string = "REMOVED";
export const REMOVE: string = "REMOVE";
export const SELECT_STATUS: string = "SELECT_STATUS";
export const UPCOMING: string = "UPCOMING";
export const PAYMENT_REFUNDED: string = "PAYMENT_REFUNDED";
export const PARKING_CHARGES: string = "PARKING_CHARGES";
export const PLATFORM_FEE_TAX: string = "PARKING_CHARGES";
export const ADD_EQUIPMENT_TYPE: string = "ADD_EQUIPMENT_TYPE";
export const TAX_NUMBER: string = "TAX_NUMBER";
export const BOOK_RESERVATION: string = "BOOK_RESERVATION";
export const RESET: string = "RESET";
export const SHARE: string = "SHARE";
export const MOBILE_APP_DRIVER: string = "MOBILE_APP_DRIVER";
export const DRIVER_EMAIL_ID: string = "DRIVER_EMAIL_ID";
export const COUNTRY_CODE: string = "COUNTRY_CODE";
export const DOT_NUMBER: string = "DOT_NUMBER";
export const CREATE_RESERVATION: string = "CREATE_RESERVATION";
export const EXTEND_CANCEL_RESERVATION: string = "EXTEND_CANCEL_RESERVATION";
export const CREDIT_CARD: string = "CREDIT_CARD";
export const USD: string = "USD";
export const CAD: string = "CAD";
export const YES: string = "YES";
export const NO: string = "NO";
export const BROWSE_FILES: string = "BROWSE_FILES";
export const DRAG_DROP: string = "DRAG_DROP";
export const OR: string = "OR";
export const TEST_PARKING_LOT: string = "TEST_PARKING_LOT";
export const UPLOAD_CSV_IMPORT: string = "UPLOAD_CSV_IMPORT";
export const UPLOAD_CSV_FILE: string = "UPLOAD_CSV_FILE";
export const FILE_NAME: string = "FILE_NAME";
export const UPLOAD: string = "UPLOAD";
export const PHONE_NO: string = "PHONE_NO";
export const ADD_TRUCK: string = "ADD_TRUCK";
export const SEARCH_PARKING_LOT: string = "SEARCH_PARKING_LOT";
export const CLEAR: string = "CLEAR";
export const SELECT_TRUCK: string = "SELECT_TRUCK";
export const CONTACT_DETAILS: string = "CONTACT_DETAILS";
export const AGE: string = "AGE";
export const FULL_NAME: string = "FULL_NAME";
export const LENGTH_TRAILER: string = "LENGTH_TRAILER";
export const ASSOCIATED_ORGANIZATION: string = "ASSOCIATED_ORGANIZATION";
export const RESERVATION_STATISTICS: string = "RESERVATION_STATISTICS";
export const GEOGRAPHICAL_INFO: string = "GEOGRAPHICAL_INFO";
export const IN_PROGRESS_RESERVATION: string = "IN_PROGRESS_RESERVATION";
export const SELECT_DRIVER: string = "SELECT_DRIVER";
export const OK: string = "OK";
export const SELECT_EQUIPMENT: string = "SELECT_EQUIPMENT";
export const SELECT_ASSET: string = "SELECT_ASSET";
export const RESERVATION_EXTEND: string = "RESERVATION_EXTEND";
export const PENDING: string = "PENDING";
export const PENDING_PAYMENT: string = "PENDING_PAYMENT";
export const PAYMENT_FAILED: string = "PAYMENT_FAILED";
export const NEAR_BY: string = "NEAR_BY";
export const ADD_RESERVATION: string = "ADD_RESERVATION";
export const BOOKINGS: string = "BOOKINGS";
export const RESERVATION_CANCELLATION_DATE: string =
  "RESERVATION_CANCELLATION_DATE";
export const FLEET_ORGANIZATION: string = "FLEET_ORGANIZATION";
export const FLEET_OWNER: string = "FLEET_OWNER";
export const SNO: string = "SNO";
export const APPLY: string = "APPLY";
export const TOTAL_SPACES: string = "TOTAL_SPACES";
export const ADD_T0_LIST: string = "ADD_T0_LIST";
export const AVAILABLE_SPACES: string = "AVAILABLE_SPACES";
export const DRIVERS_ASSET_DETAILS: string = "DRIVERS_ASSET_DETAILS";
export const SEARCH_DRIVER: string = "SEARCH_DRIVER";
export const VALIDATE_PAYMENT: string = "VALIDATE_PAYMENT";
export const ACCOUNT_VERIFIED: string = "ACCOUNT_VERIFIED";
export const ACCOUNT_NOT_VERIFIED: string = "ACCOUNT_NOT_VERIFIED";
export const VERIFY_ACCOUNT_IN_PROGRESS: string = "VERIFY_ACCOUNT_IN_PROGRESS";
export const TRANSACTION_VERIFY_SUCCESS: string = "TRANSACTION_VERIFY_SUCCESS";
export const TRANSACTION_VERIFY_FAILED: string = "TRANSACTION_VERIFY_FAILED";
export const TRANSACTION_VERIFY_IN_PROGRESS: string =
  "TRANSACTION_VERIFY_IN_PROGRESS";
export const NO_OF_SPACES_RESERVE: string = "NO_OF_SPACES_RESERVE";
export const HOLDER_NAME: string = "HOLDER_NAME";
export const NICKNAME: string = "NICKNAME";
export const MM_YY: string = "MM_YY";
export const CVV: string = "CVV";
export const UPLOAD_VOID_CHEQUE: string = "UPLOAD_VOID_CHEQUE";
export const DOCUMENT_MANAGEMENT: string = "DOCUMENT_MANAGEMENT";
export const DOCUMENT_NAME: string = "DOCUMENT_NAME";
export const INSURANCE: string = "INSURANCE";
export const AGREEMENT: string = "AGREEMENT";
export const AGREEMENT_LINK: string = "AGREEMENT_LINK";
export const SIGNED_DATE: string = "SIGNED_DATE";
export const EXPIRY_DATE: string = "EXPIRY_DATE";
export const DOWNLOAD: string = "DOWNLOAD";
export const SALES_AGENT: string = "SALES_AGENT";
export const SALES_AGENT_PHONE: string = "SALES_AGENT_PHONE";
export const NAME: string = "NAME";
export const COMMISION_PERCENTAGE: string = "COMMISION_PERCENTAGE";
export const SAVE_CARDS: string = "SAVE_CARDS";
export const AGREEMENT_DOCUMENT: string = "AGREEMENT_DOCUMENT";
export const CARRIER_INFO: string = "CARRIER_INFO";
export const CARRIER_ASSETS: string = "CARRIER_ASSETS";
export const COMPANY_INFO: string = "COMPANY_INFO";
export const ASSET_INFO: string = "ASSET_INFO";
export const USER_DETAILS: string = "USER_DETAILS";
export const GENERAL_INFO: string = "GENERAL_INFO";
export const SALES_PERSON_NAME: string = "SALES_PERSON_NAME";
export const SALES_REFERENCE_NUMBER: string = "SALES_REFERENCE_NUMBER";
export const DELIVERY_DATE: string = "DELIVERY_DATE";
export const CARRIER_NAME: string = "CARRIER_NAME";
export const EDIT_DRIVER_DETAILS: string = "EDIT_DRIVER_DETAILS";
export const ADD_DRIVER_DETAILS: string = "ADD_DRIVER_DETAILS";
export const SERVICE_TYPE: string = "SERVICE_TYPE";
export const DO_YOU_WANT_TO_DELETE: string = "DO_YOU_WANT_TO_DELETE";
export const CONSOLIDATION_INVOICE_ID: string = "CONSOLIDATION_INVOICE_ID";
export const PROCEED_TO_PAY: string = "PROCEED_TO_PAY";
export const MONTH_YEAR: string = "MONTH_YEAR";
export const LOCATION: string = "LOCATION";
export const ORGANIZATION_LIST: string = "ORGANIZATION_LIST";
export const INVOICE_LIST: string = "INVOICE_LIST";
export const SIGN_UP: string = "SIGN_UP";
export const NO_ACCOUNT: string = "NO_ACCOUNT";
export const ACTIVE_PLAN: string = "ACTIVE_PLAN";
export const PERSONAL_INFO: string = "PERSONAL_INFO";
export const DRIVER_PROFILE: string = "DRIVER_PROFILE";
export const CARRIER_PROFILE: string = "CARRIER_PROFILE";
export const TECH_SUPPORT: string = "TECH_SUPPORT";
export const SUBSCRIBE: string = "SUBSCRIBE";
export const UPGRADE: string = "UPGRADE";
export const CANCEL_PLAN: string = "CANCEL_PLAN";
export const SUBSCRIPTION_PLAN: string = "SUBSCRIPTION_PLAN";
export const SUBSCRIPTION_START_DATE_TIME: string =
  "SUBSCRIPTION_START_DATE_TIME";
export const CYCLE_START_DATE_TIME: string = "CYCLE_START_DATE_TIME";
export const CYCLE_END_DATE_TIME: string = "CYCLE_END_DATE_TIME";
export const NEXT_CYCLE: string = "NEXT_CYCLE";
export const LATEST_CARD_NUMBER: string = "LATEST_CARD_NUMBER";
export const CANCELLATION_DATE: string = "CANCELLATION_DATE";
export const UPGRADE_INITIATED: string = "UPGRADE_INITIATED";
export const LANGUAGE: string = "LANGUAGE";
export const PLEASE_ENTER_VALID_CREDENTIALS: string =
  "PLEASE_ENTER_VALID_CREDENTIALS";
export const ASSOCIATE_ORGANIZATION_LIST: string =
  "ASSOCIATE_ORGANIZATION_LIST";
export const SELECT_CREDIT_CARD: string = "SELECT_CREDIT_CARD";
export const PAYMENT_VIA_MOBILE: string = "PAYMENT_VIA_MOBILE";
export const TOTAL_PAYABLE: string = "TOTAL_PAYABLE";
export const NO_CARDS_AVAILABLE: string = "NO_CARDS_AVAILABLE";
export const PARKING_LOT_OWNER: string = "PARKING_LOT_OWNER";
export const CARRIER: string = "CARRIER";
export const BROKER: string = "BROKER";
export const RESERVATION_INVOICES: string = "RESERVATION_INVOICES";
export const UN_PAID_TRANSACTIONS: string = "UN_PAID_TRANSACTIONS";
export const PAID_TRANSACTIONS: string = "PAID_TRANSACTIONS";
export const GENERAL_PARKING: string = "GENERAL_PARKING";
export const ADMIN: string = "ADMIN";
export const MANAGER: string = "MANAGER";
export const VIN_NUMBER: string = "VIN_NUMBER";
export const SUBSCRIPTION_ID: string = "SUBSCRIPTION_ID";
export const VIEW_MORE_DISCOUNT_CODES: string = "VIEW_MORE_DISCOUNT_CODES";
export const API_DETAILS: string = "API_DETAILS";
export const ORGANIZATION_API_KEY: string = "ORGANIZATION_API_KEY";
export const DOT_ALREADY_REGISTERED: string = "DOT_ALREADY_REGISTERED";
export const CONTINUE_CHANGE_DOT: string = "CONTINUE_CHANGE_DOT";
export const CONFIRM_RESERVATION: string = "CONFIRM_RESERVATION";
export const MESSAGE: string = "MESSAGE";
export const TOTAL_RESERVATIONS: string = "TOTAL_RESERVATIONS";
export const BOOKED_RESERVATIONS: string = "BOOKED_RESERVATIONS";
export const PENDING_RESERVATIONS: string = "PENDING_RESERVATIONS";
export const MAP_SEARCH: string = "MAP_SEARCH";
export const CHOOSE_DESTINATION: string = "CHOOSE_DESTINATION";
export const CURRENT_LOCATION: string = "CURRENT_LOCATION";
export const PAID_RESERVATIONS: string = "PAID_RESERVATIONS";
export const UNPAID_RESERVATIONS: string = "UNPAID_RESERVATIONS";
export const CREATED_RESERVATIONS: string = "CREATED_RESERVATIONS";
export const FAILED_RESERVATIONS: string = "FAILED_RESERVATIONS";
export const ENTER_OLD_PASSWORD: string = "ENTER_OLD_PASSWORD";
export const ENTER_NEW_PASSWORD: string = "ENTER_NEW_PASSWORD";
export const ENTER_CONFIRMED_NEW_PASSWORD: string =
  "ENTER_CONFIRMED_NEW_PASSWORD";
export const YOUR_PASSWORD_DO_NOT_MATCH: string = "YOUR_PASSWORD_DO_NOT_MATCH";
export const COLUMNS: string = "COLUMNS";
export const ENTER_DRIVER_NAME: string = "ENTER_DRIVER_NAME";
export const DRIVER_NAME_3_LETTERS: string = "DRIVER_NAME_3_LETTERS";
export const SELECT_DRIVER_TYPE: string = "SELECT_DRIVER_TYPE";
export const ENTER_EMAIL: string = "ENTER_EMAIL";
export const ENTER_PHONE_NUMBER: string = "ENTER_PHONE_NUMBER";
export const ENTER_COUNTRY_CODE: string = "ENTER_COUNTRY_CODE";
export const ENTER_STATUS: string = "ENTER_STATUS";
export const SUCCESSFULLY_CREATED_DRIVER: string =
  "SUCCESSFULLY_CREATED_DRIVER";
export const ERROR_WHILE_SAVING: string = "ERROR_WHILE_SAVING";
export const DOWNLOAD_SAMPLE_CSV_FILE: string = "DOWNLOAD_SAMPLE_CSV_FILE";
export const ROW_NUMBER: string = "ROW_NUMBER";
export const ERROR_MESSAGE: string = "ERROR_MESSAGE";
export const CSV_IMPORT_FILE: string = "CSV_IMPORT_FILE";
export const FIND_SOMETHING_HERE: string = "FIND_SOMETHING_HERE";
export const WEEKLY: string = "WEEKLY";
export const MONTHLY: string = "MONTHLY";
export const YEARLY: string = "YEARLY";
export const HRS: string = "HRS";
export const RETRY_PAYMENT: string = "RETRY_PAYMENT";
export const NEW_RESERVATIONS: string = "NEW_RESERVATIONS";
export const EXTENSIONS: string = "EXTENSIONS";
export const NEW_DRIVERS: string = "NEW_DRIVERS";
export const GUEST_DRIVERS: string = "GUEST_DRIVERS";
export const NEW_PARKING_LOTS: string = "NEW_PARKING_LOTS";
export const NEW_PARKING_USERS: string = "NEW_PARKING_USERS";
export const DATE_RANGE: string = "DATE_RANGE";
export const LAST_7_DAYS: string = "LAST_7_DAYS";
export const LAST_30_DAYS: string = "LAST_30_DAYS";
export const LAST_90_DAYS: string = "LAST_90_DAYS";
export const BILL_TO: string = "BILL_TO";
export const SERVICE_CODE: string = "SERVICE_CODE";
export const FAILURE_TIME: string = "FAILURE_TIME";
export const ENTER_FIRST_NAME: string = "ENTER_FIRST_NAME";
export const ENTER_LAST_NAME: string = "ENTER_LAST_NAME";
export const ONLY_ALPHABETS: string = "ONLY_ALPHABETS";
export const FIRST_NAME_LESS_THAN_64: string = "FIRST_NAME_LESS_THAN_64";
export const LAST_NAME_LESS_THAN_64: string = "LAST_NAME_LESS_THAN_64";
export const EMAIL_LESS_THAN_64: string = "EMAIL_LESS_THAN_64";
export const ENTER_CAPTCHA: string = "ENTER_CAPTCHA";
export const TAX_9_DIGITS: string = "TAX_9_DIGITS";
export const ENTER_ADDRESS: string = "ENTER_ADDRESS";
export const ENTER_CITY: string = "ENTER_CITY";
export const ENTER_STATE: string = "ENTER_STATE";
export const ENTER_COUNTRY: string = "ENTER_COUNTRY";
export const ENTER_ZIP_CODE: string = "ENTER_ZIP_CODE";
export const UPDATED_SUCCESSFULLY: string = "UPDATED_SUCCESSFULLY";
export const ENTER_NAME: string = "ENTER_NAME";
export const ENTER_LICENSE_NUMBER: string = "ENTER_LICENSE_NUMBER";
export const ENTER_MAKE_MODEL: string = "ENTER_MAKE_MODEL";
export const ENTER_MAKE_YEAR: string = "ENTER_MAKE_YEAR";
export const ENTER_VALID_NUMBER: string = "ENTER_VALID_NUMBER";
export const ENTER_COLOR: string = "ENTER_COLOR";
export const ADDED_SUCCESSFULLY: string = "ADDED_SUCCESSFULLY";
export const ENTER_VALID_EMAILS: string = "ENTER_VALID_EMAILS";
export const SELECT_YOUR_ROLE: string = "SELECT_YOUR_ROLE";
export const SELECT_SPACE_TYPE: string = "SELECT_SPACE_TYPE";
export const SELECT_TERM: string = "SELECT_TERM";
export const SELECT_HOURS: string = "SELECT_HOURS";
export const ADD_TOTAL_RATE: string = "ADD_TOTAL_RATE";
export const RATE_NOT_0_LESS_THAN_0: string = "RATE_NOT_0_LESS_THAN_0";
export const ENTER_PARKING_LOT_NAME: string = "ENTER_PARKING_LOT_NAME";
export const SELECT_CURRENCY: string = "SELECT_CURRENCY";
export const ENTER_TIME_ZONE_CODE: string = "ENTER_TIME_ZONE_CODE";
export const CODE_LESS_THAN_32_CHAR: string = "CODE_LESS_THAN_32_CHAR";
export const SLOT_COUNT_INTEGER: string = "SLOT_COUNT_INTEGER";
export const ENTER_SLOT_COUNT: string = "ENTER_SLOT_COUNT";
export const ENTER_ASSET_NAME: string = "ENTER_ASSET_NAME";
export const ENTER_ASSET_COLOR: string = "ENTER_ASSET_COLOR";
export const SELECT_ASSET_TYPE: string = "SELECT_ASSET_TYPE";
export const SELECT_EQUIPMENT_TYPE: string = "SELECT_EQUIPMENT_TYPE";
export const ENTER_NO_OF_AXELS: string = "ENTER_NO_OF_AXELS";
export const ENTER_LENGTH_OF_TRAILER: string = "ENTER_LENGTH_OF_TRAILER";
export const ENTER_VIN_NUMBER: string = "ENTER_VIN_NUMBER";
export const VIN_AT_LEAST_17_CHAR: string = "VIN_AT_LEAST_17_CHAR";
export const VIN_AT_MOST_17_CHAR: string = "VIN_AT_MOST_17_CHAR";
export const NO_PERIOD: string = "NO_PERIOD";
export const NO_SYMBOLS: string = "NO_SYMBOLS";
export const ENTER_DISCOUNT_CODE_NAME: string = "ENTER_DISCOUNT_CODE_NAME";
export const DISCOUNT_CODE_MIN_6_ALPHA_NUMERIC: string =
  "DISCOUNT_CODE_MIN_6_ALPHA_NUMERIC";
export const DISCOUNT_CODE_50_ALPHA_NUMERIC: string =
  "DISCOUNT_CODE_50_ALPHA_NUMERIC";
export const ENTER_DISCOUNT_TYPE: string = "ENTER_DISCOUNT_TYPE";
export const PERCENTAGE_NOT_GREATER_THAN_100: string =
  "PERCENTAGE_NOT_GREATER_THAN_100";
export const LESS_THAN_BALANCE_AND_TOTAL_VALUE: string =
  "LESS_THAN_BALANCE_AND_TOTAL_VALUE";
export const MUST_GREATER_THAN_0: string = "MUST_GREATER_THAN_0";
export const ENTER_TOTAL_VALUE: string = "ENTER_TOTAL_VALUE";
export const MUST_GREATER_THAN_AMOUNT: string = "MUST_GREATER_THAN_AMOUNT";
export const MUST_GREATER_THAN_MAX_LIMIT_PER_TRANSACTION: string =
  "MUST_GREATER_THAN_MAX_LIMIT_PER_TRANSACTION";
export const ENTER_VALID_COUNTRY: string = "ENTER_VALID_COUNTRY";
export const SELECT_STATE: string = "SELECT_STATE";
export const SELECT_START_DATE: string = "SELECT_START_DATE";
export const SELECT_END_DATE: string = "SELECT_END_DATE";
export const ENTER_INSTRUCTION: string = "ENTER_INSTRUCTION";
export const NOT_EXCEED_1000_CHAR: string = "NOT_EXCEED_1000_CHAR";
export const CHECK_AT_LEAST_1_AFOEREMENTIONED: string =
  "CHECK_AT_LEAST_1_AFOEREMENTIONED";
export const ENTER_COMPANY_NAME: string = "ENTER_COMPANY_NAME";
export const ENTER_COMPANY_ADDRESS: string = "ENTER_COMPANY_ADDRESS";
export const ENTER_CURRENCY: string = "ENTER_CURRENCY";
export const ENTER_ACCOUNT_NUMBER: string = "ENTER_ACCOUNT_NUMBER";
export const ENTER_NUMBER: string = "ENTER_NUMBER";
export const ENTER_TYPE: string = "ENTER_TYPE";
export const ENTER_SWIFT_CODE: string = "ENTER_SWIFT_CODE";
export const ENTER_FINANCIAL_INSTITUTION_NUMBER: string =
  "ENTER_FINANCIAL_INSTITUTION_NUMBER";
export const ENTER_TRANSIT_NUMBER: string = "ENTER_TRANSIT_NUMBER";
export const ENTER_DOC_NAME: string = "ENTER_DOC_NAME";
export const SELECT_CATEGORY: string = "SELECT_CATEGORY";
export const ATTACH_DOC: string = "ATTACH_DOC";
export const ENTER_SIGNED_DATE: string = "ENTER_SIGNED_DATE";
export const EXPIRY_DATE_CANT_BE_BEFORE_SIGNED_DATE: string =
  "EXPIRY_DATE_CANT_BE_BEFORE_SIGNED_DATE";
export const ENTER_EXPIRY_DATE: string = "ENTER_EXPIRY_DATE";
export const ORGANIZATION_NAME_LESS_THAN_35: string =
  "ORGANIZATION_NAME_LESS_THAN_35";
export const ORGANIZATION_NAME_LESS_THAN_65: string =
  "ORGANIZATION_NAME_LESS_THAN_65";
export const ORGANIZATION_NAME_REQUIRED: string = "ORGANIZATION_NAME_REQUIRED";
export const ENTER_ORGANIZATION_NAME: string = "ENTER_ORGANIZATION_NAME";
export const ENTER_ORGANIZATION_LEGAL_NAME: string =
  "ENTER_ORGANIZATION_LEGAL_NAME";
export const DOT_NUMBER_CONTAIN_8_12: string = "DOT_NUMBER_CONTAIN_8_12";
export const ITS_NOT_VALID_NUMBER: string = "ITS_NOT_VALID_NUMBER";
export const ENTER_VALID_DOT: string = "ENTER_VALID_DOT";
export const ENTER_ABA_ROUTING_NUMBER: string = "ENTER_ABA_ROUTING_NUMBER";
export const EXACTLY_9_DIGITS: string = "EXACTLY_9_DIGITS";
export const EXACTLY_5_DIGITS: string = "EXACTLY_5_DIGITS";
export const EXACTLY_3_DIGITS: string = "EXACTLY_3_DIGITS";
export const ENTER_TRANSFER_TYPE: string = "ENTER_TRANSFER_TYPE";
export const ENTER_SALES_AGENT_NAME: string = "ENTER_SALES_AGENT_NAME";
export const ENTER_SALES_AGENT_NUMBER: string = "ENTER_SALES_AGENT_NUMBER";
export const INVALID_EMAIL_FORMAT: string = "INVALID_EMAIL_FORMAT";
export const SALES_AGENT_EMAIL_REQUIRED: string = "SALES_AGENT_EMAIL_REQUIRED";
export const ENTER_SALES_AGENT_COMMISION_PERCENTAGE: string =
  "ENTER_SALES_AGENT_COMMISION_PERCENTAGE";
export const SELECT_DRIVER_DATE: string = "SELECT_DRIVER_DATE";
export const VIN_NUMBER_ALREADY_ENTERED: string = "VIN_NUMBER_ALREADY_ENTERED";
export const LICENSE_NUMBER_ALREADY_ENTERED: string =
  "LICENSE_NUMBER_ALREADY_ENTERED";
export const MAX_LENGTH_15: string = "MAX_LENGTH_15";
export const SELECT_VIN_NUMBER: string = "SELECT_VIN_NUMBER";
export const PROVIDE_VALID_USER_NAME: string = "PROVIDE_VALID_USER_NAME";
export const PROVIDE_VALID_PASSWORD: string = "PROVIDE_VALID_PASSWORD";
export const PASSWORD_DO_NOT_MATCH: string = "PASSWORD_DO_NOT_MATCH";
export const ENTER_COMPANY_REGISTRATION_NUMBER: string =
  "ENTER_COMPANY_REGISTRATION_NUMBER";
export const MUST_9_10_DIGITS: string = "MUST_9_10_DIGITS";
export const ENTER_VALID_URL: string = "ENTER_VALID_URL";
export const URL_NOT_VALID: string = "URL_NOT_VALID";
export const ERROR_SAVING_AMENITIES: string = "ERROR_SAVING_AMENITIES";
export const ENTER_VALID_CODE: string = "ENTER_VALID_CODE";
export const NO_AVAILABLE_COUPON_BALANCE: string =
  "NO_AVAILABLE_COUPON_BALANCE";
export const ALREADY_USED_INVALID_CODE: string = "ALREADY_USED_INVALID_CODE";
export const BOOKING_CANCELLED_SUCCESSFULLY: string =
  "BOOKING_CANCELLED_SUCCESSFULLY";
export const PLZ_SELECT_PARKING_LOT: string = "PLZ_SELECT_PARKING_LOT";
export const PLZ_SELECT_DRIVER_DETAILS: string = "PLZ_SELECT_DRIVER_DETAILS";
export const SUCCESSFULLY_CANCELLED_RESERVATION: string =
  "SUCCESSFULLY_CANCELLED_RESERVATION";
export const PLZ_SELECT_ONE_RESERVATION_TO_CANCEL: string =
  "PLZ_SELECT_ONE_RESERVATION_TO_CANCEL";
export const SUCCESSFULLY_PAID: string = "SUCCESSFULLY_PAID";
export const PAYMENT_LINK_SENT_SUCCESSFULLY: string =
  "PAYMENT_LINK_SENT_SUCCESSFULLY";
export const SAVED_SUCCESSFULLY: string = "SAVED_SUCCESSFULLY";
export const DRIVER_UPDATED_SUCCESSFULLY: string =
  "DRIVER_UPDATED_SUCCESSFULLY";
export const DISCOUNT_CODE_UPDATED_SUCCESSFULLY: string =
  "DISCOUNT_CODE_UPDATED_SUCCESSFULLY";
export const ASSOCIATION_UPDATED_SUCCESSFULLY: string =
  "ASSOCIATION_UPDATED_SUCCESSFULLY";
export const ASSET_UPDATED_SUCCESSFULLY: string = "ASSET_UPDATED_SUCCESSFULLY";
export const DELETED_SUCCESSFULLY: string = "DELETED_SUCCESSFULLY";
export const DRIVER_DELETED_SUCCESSFULLY: string =
  "DRIVER_DELETED_SUCCESSFULLY";
export const APPROVED_SUCCESSFULLY: string = "APPROVED_SUCCESSFULLY";
export const ASSOCIATED_SUCCESSFULLY: string = "ASSOCIATED_SUCCESSFULLY";
export const AMENITIES_SAVED_SUCCESSFULLY: string =
  "AMENITIES_SAVED_SUCCESSFULLY";
export const ASSETS_ADDED_TO_ORGANIZATION: string =
  "ASSETS_ADDED_TO_ORGANIZATION";
export const MAIL_SENT_SUCCESSFULLY: string = "MAIL_SENT_SUCCESSFULLY";
export const UNASSIGNED_SUCCESSFULLY: string = "UNASSIGNED_SUCCESSFULLY";
export const PAYMENT_MADE_SUCCESSFULLY: string = "PAYMENT_MADE_SUCCESSFULLY";
export const CREDIT_CARD_TRANSACTION_SUCCESSFUL: string =
  "CREDIT_CARD_TRANSACTION_SUCCESSFUL";
export const FILE_UPLOADED_SUCCESSFULLY: string = "FILE_UPLOADED_SUCCESSFULLY";
export const INVITATION_SENT: string = "INVITATION_SENT";
export const TRANSACTION_VERIFICATION_SUCCESSFUL: string =
  "TRANSACTION_VERIFICATION_SUCCESSFUL";
export const SUBSCRIPTION_UPGRADED_SUCCESSFULLY: string =
  "SUBSCRIPTION_UPGRADED_SUCCESSFULLY";
export const CONTACT_DETAILS_SUCCESSFULLY: string =
  "CONTACT_DETAILS_SUCCESSFULLY";
export const BOOKING_DELETED_SUCCESSFULLY: string =
  "BOOKING_DELETED_SUCCESSFULLY";
export const PASSWORD_SET_SUCCESSFUL: string = "PASSWORD_SET_SUCCESSFUL";
export const LINK_TO_RESET_PASSWORD_SENT_ON_EMAIL: string =
  "LINK_TO_SET_PASSWORD_SENT_ON_EMAIL";
export const ERROR_RESETTING_PASSWORD: string = "ERROR_RESETTING_PASSWORD";
export const ERROR_RETRIEVING_DATA: string = "ERROR_RETRIEVING_DATA";
export const ERROR_PERFORMING_OPERATION: string = "ERROR_PERFORMING_OPERATION";
export const ERROR_ASSOCIATING: string = "ERROR_ASSOCIATING";
export const ERROR_UPLOADING_IMAGE: string = "ERROR_UPLOADING_IMAGE";
export const PLZ_SELECT_ATLEAST_1_IMAGE: string = "PLZ_SELECT_ATLEAST_1_IMAGE";
export const ERROR_WHILE_DELETING_AMENITY: string =
  "ERROR_WHILE_DELETING_AMENITY";
export const ERROR_VIEWING_AMENITY: string = "ERROR_VIEWING_AMENITY";
export const ERROR_RETRIEVING_INFO: string = "ERROR_RETRIEVING_INFO";
export const ERROR_RETRIEVING_AMENITIES_DETAILS: string =
  "ERROR_RETRIEVING_AMENITIES_DETAILS";
export const ERROR_RETRIEVING_DETAILS: string = "ERROR_RETRIEVING_DETAILS";
export const ERROR_UPDATING_AMENITY: string = "ERROR_UPDATING_AMENITY";
export const ERROR_UPLOADING_FILES: string = "ERROR_UPLOADING_FILES";
export const ERROR_OCCURRED_WHILE_SAVING: string =
  "ERROR_OCCURRED_WHILE_SAVING";
export const ERROR_OCCURRED_TRY_AGAIN: string = "ERROR_OCCURRED_TRY_AGAIN";
export const ERROR_RETRIEVING_AMENITIES: string = "ERROR_RETRIEVING_AMENITIES";
export const SOMETHING_WENT_WRONG: string = "SOMETHING_WENT_WRONG";
export const PLZ_ENTER_VALID_EXPIRY_DATE: string =
  "PLZ_ENTER_VALID_EXPIRY_DATE";
export const PLZ_SELECT_VALID_IMAGE: string = "PLZ_SELECT_VALID_IMAGE";
export const PLZ_ADD_AT_LEAST_1_ASSET: string = "PLZ_ADD_AT_LEAST_1_ASSET";
export const PLZ_SELECT_AT_LEAST_1_ENTRY: string =
  "PLZ_SELECT_AT_LEAST_1_ENTRY";
export const PLZ_SELECT_FLEET_ORG: string = "PLZ_SELECT_FLEET_ORG";
export const PLZ_SELECT_FROM_DATE_DURATION: string =
  "PLZ_SELECT_FROM_DATE_DURATION";
export const FROM_DATE_REQUIRED: string = "FROM_DATE_REQUIRED";
export const DURATION_REQUIRED: string = "DURATION_REQUIRED";
export const DRIVER_ASSET_REQUIRED: string = "DRIVER_ASSET_REQUIRED";
export const CODE_AT_LEAST_32_CHAR: string = "CODE_AT_LEAST_32_CHAR";
export const PARKING_LOT_OWNER_CANNOT_BE_ADDED_CARRIER: string =
  "PARKING_LOT_OWNER_CANNOT_BE_ADDED_CARRIER";
export const COUPON_NOT_HAVING_AVAILABLE_COUPON_COUNT: string =
  "COUPON_NOT_HAVING_AVAILABLE_COUPON_COUNT";
export const SLOT_ALREADY_BOOKED: string = "SLOT_ALREADY_BOOKED";
export const PLZ_ENTER_REGISTERED_EMAILID: string =
  "PLZ_ENTER_REGISTERED_EMAILID";
export const RECORD_NOT_FOUND: string = "RECORD_NOT_FOUND";
export const MODIFY: string = "MODIFY";
export const VALID_GOOGLE_ADDRESS: string = "VALID_GOOGLE_ADDRESS";
export const MUST_9_DIGITS: string = "MUST_9_DIGITS";
export const SELECT_ORGANIZATION_TYPE: string = "SELECT_ORGANIZATION_TYPE";
export const ENTER_CORRECT_URL: string = "ENTER_CORRECT_URL";
export const ENTER_BUSINESS_WEBSITE_URL: string = "ENTER_BUSINESS_WEBSITE_URL";
export const ACCOUNT_APPROVAL_PENDING: string = "ACCOUNT_APPROVAL_PENDING";
export const APPROVE: string = "APPROVE";
export const SALES_PERSON_NAME_REQUIRED: string = "SALES_PERSON_NAME_REQUIRED";
export const SALES_PERSON_NUMBER_REQUIRED: string =
  "SALES_PERSON_NUMBER_REQUIRED";
export const SELECT_DELIVERY_DATE: string = "SELECT_DELIVERY_DATE";
export const SPONSOR_ORG: string = "SPONSOR_ORG";
export const PLZ_SELECT_AT_LEAST_1_RECIPIENT: string =
  "PLZ_SELECT_AT_LEAST_1_RECIPIENT";
export const PLZ_SELECT_RECIPIENT: string = "PLZ_SELECT_RECIPIENT";
export const SELECT_VALID_RECIPIENT: string = "SELECT_VALID_RECIPIENT";
export const SELECT_VALID_ORG: string = "SELECT_VALID_ORG";
export const ENTER_VALID_CREDENTIALS: string = "ENTER_VALID_CREDENTIALS";
export const DOT_SAME_ROOT_CANNOT_BE_ADDED: string =
  "DOT_SAME_ROOT_CANNOT_BE_ADDED";
export const ADD_CREDIT_CARD: string = "ADD_CREDIT_CARD";
export const SALES_INFO: string = "SALES_INFO";
export const ATTEMPTS_LEFT_TO_VALIDATE: string = "ATTEMPTS_LEFT_TO_VALIDATE";
export const VERIFICATION_LIMIT_EXHAUST: string = "VERIFICATION_LIMIT_EXHAUST";
export const TRANSACTION_VERIFICATION_TIMEOUT: string =
  "TRANSACTION_VERIFICATION_TIMEOUT";
export const NOTE_ADMIN_VERIFY_DEPOSIT_BANK_ACCOUNT: string =
  "NOTE_ADMIN_VERIFY_DEPOSIT_BANK_ACCOUNT";
export const NOTE_NECESSARY_DETAILS_CITY_STATE_COUNTRY: string =
  "NOTE_NECESSARY_DETAILS_CITY_STATE_COUNTRY";
export const TAX_COMPANY_REG_NUMBER = "TAX_COMPANY_REG_NUMBER";
export const TERMS_CONDITIONS = "TERMS_CONDITIONS";
export const AND = "AND";
export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const ALLRIGHTS_RESERVED = "ALLRIGHTS_RESERVED";
export const CLICK_CREATE_PROFILE = "CLICK_CREATE_PROFILE";
export const ENTER_6_ALPHA_NUMERIC_CHAR = "ENTER_6_ALPHA_NUMERIC_CHAR";
export const AFTER_CHECKING_DRIVER_CODE_1_RESERVATION =
  "AFTER_CHECKING_DRIVER_CODE_1_RESERVATION";
export const AUTOMATICALLY_ADD_DISCOUNT_CODE_ALL_MOBILE_USERS =
  "AUTOMATICALLY_ADD_DISCOUNT_CODE_ALL_MOBILE_USERS";
export const SELECTING_THIS_OPTION_EMAIL_DISCOUNT_CODE_OTHERS =
  "SELECTING_THIS_OPTION_EMAIL_DISCOUNT_CODE_OTHERS";
export const GENERAL_MANAGEMENT = "GENERAL_MANAGEMENT";
export const SPONSOR_NAME = "SPONSOR_NAME";
export const INVALIDAMOUNT = "INVALIDAMOUNT";
export const ENTER_VALID_AMOUNT_DEBITED_CREDITED_BANK =
  "ENTER_VALID_AMOUNT_DEBITED_CREDITED_BANK";
export const ENTER_DEBITED_AMOUNT = "ENTER_DEBITED_AMOUNT";
export const RE_GENERAL_KEY = "RE_GENERAL_KEY";
export const GENERAL_KEY = "GENERAL_KEY";
export const PAYMENT_OPTIONS = "PAYMENT_OPTIONS";
export const SELECT_PAYMENT_OPTIONS = "SELECT_PAYMENT_OPTIONS";
export const PLZ_SELECT_CARD = "PLZ_SELECT_CARD";
export const AGREE_TO = "AGREE_TO";
export const SELECT_DURATION = "SELECT_DURATION";
export const AUTO_DEBIT = "AUTO_DEBIT";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const PENDING_PAYMENT_DETAILS = "PENDING_PAYMENT_DETAILS";
export const NEXT_CYCLE_START_DATE_TIME = "NEXT_CYCLE_START_DATE_TIME";
export const TOTAL_PAID_INVOICES = "TOTAL_PAID_INVOICES";
export const BANK_ACCOUNT = "BANK_ACCOUNT";
export const AUTO_PAY = "AUTO_PAY";
export const SELECT_PAYMENT_TYPE = "SELECT_PAYMENT_TYPE";
export const SELECT_BANK = "SELECT_BANK";
export const BANK = "BANK";
export const AUTO_PAY_PROCEEDED_CARD = "AUTO_PAY_PROCEEDED_CARD";
export const BANK_ACCOUNT_NOT_VERIFIED = "BANK_ACCOUNT_NOT_VERIFIED";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const FUTURE_RESERVATIONS_UNPAID_CANCEL =
  "FUTURE_RESERVATIONS_UNPAID_CANCEL";
export const CHANGE_RESERVATION_START_DATE = "CHANGE_RESERVATION_START_DATE";
export const NO_OF_INVOICES = "NO_OF_INVOICES";
export const INVOICE_CODE = "INVOICE_CODE";
export const CREATE_BANK_DETAILS = "CREATE_BANK_DETAILS";
export const UPDATE_BANK_DETAILS = "UPDATE_BANK_DETAILS";
export const VERIFY_ACCOUNT_INSTANTLY = "VERIFY_ACCOUNT_INSTANTLY";
export const MAKE_TEST_PARKING_LOT = "MAKE_TEST_PARKING_LOT";
export const CANCELLATIONS_CONFIRMATION = "CANCELLATIONS_CONFIRMATION";
export const DEDUCTION_7_DAYS_END_DATE = "DEDUCTION_7_DAYS_END_DATE";
export const DEDUCTION_4_DAYS_END_DATE = "DEDUCTION_4_DAYS_END_DATE";
export const USER_DETAILS_SUCCESSFULLY_UPDATED =
  "USER_DETAILS_SUCCESSFULLY_UPDATED";
export const USER_DETAILS_SUCCESSFULLY_ADDED =
  "USER_DETAILS_SUCCESSFULLY_ADDED";
export const TEST_ORGANIZATION = "TEST_ORGANIZATION";
export const DIVER_ASSET_DETAILS_REQUIRED = "DIVER_ASSET_DETAILS_REQUIRED";
export const SUBSCRIPTION_CANCELLED_RESERVATION_PRICE_WILL_EFFECT =
  "SUBSCRIPTION_CANCELLED_RESERVATION_PRICE_WILL_EFFECT";
export const SUBSCRIPTION_DISCOUNT = "SUBSCRIPTION_DISCOUNT";
export const PAYMENT_INDIVIDUAL_DRIVERS = "PAYMENT_INDIVIDUAL_DRIVERS";
export const PAYMENT_FLEET_OWNER = "PAYMENT_FLEET_OWNER";
export const FLEET_OWNER_PAYMENT_DETAILS = "FLEET_OWNER_PAYMENT_DETAILS";
export const CHARGES = "CHARGES";
export const RESERVATION_FAILED_POSSIBILITIES =
  "RESERVATION_FAILED_POSSIBILITIES";
export const DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT";
export const COUPON_CODE = "COUPON_CODE";
export const RESERVATION_IN_PROCESS_VISIBLE_SHORTLY =
  "RESERVATION_IN_PROCESS_VISIBLE_SHORTLY";
export const MAKE_YEAR_4_DIGIT_NUMBER = "MAKE_YEAR_4_DIGIT_NUMBER";
export const ACTIVE_UPCOMING_RESERVATIONS: string =
  "ACTIVE_UPCOMING_RESERVATIONS";
export const PENDING_APPROVAL: string = "PENDING_APPROVAL";
export const NOT_VERIFIED: string = "NOT_VERIFIED";
export const SUBSCRIBED_ASSET: string = "SUBSCRIBED_ASSET";
export const ALL_PARKING_LOT_INACTIVE: string = "ALL_PARKING_LOT_INACTIVE";
export const DO_YOU_WANT_TO_CONTINUE: string = "DO_YOU_WANT_TO_CONTINUE";
export const REFUNDED: string = "REFUNDED";
export const COMPANY_LEGAL_NAME = "COMPANY_LEGAL_NAME";
export const WEBSITE = "WEBSITE";
export const ADD_OPEN_ACCOUNT = "ADD_OPEN_ACCOUNT";
export const CREATE_OPEN_ACCOUNT = "CREATE_OPEN_ACCOUNT";
export const UPDATE_OPEN_ACCOUNT = "UPDATE_OPEN_ACCOUNT";
export const ACCOUNT_NAME = "ACCOUNT_NAME";
export const ACCOUNT_TYPE = "ACCOUNT_TYPE";
export const ENTER_GL_NUMBER = "ENTER_GL_NUMBER";
export const ENTER_CREDIT_AMOUNT = "ENTER_CREDIT_AMOUNT";
export const COMPANY_DBA_NAME = "COMPANY_DBA_NAME";
export const ENTER_ACCOUNT_NAME = "ENTER_ACCOUNT_NAME";
export const ENTER_ACCOUNT_TYPE = "ENTER_ACCOUNT_TYPE";
export const ENTER_CREDIT_LIMIT = "ENTER_CREDIT_LIMIT";
export const RENTER_NAME = "RENTER_NAME";
export const PLEASE_FILL_REQUIRED_FIELDS = "PLEASE_FILL_REQUIRED_FIELDS";
export const COUPON_NOT_HAVING_AVAILABLE_COUPON_BALANCE =
  "COUPON_NOT_HAVING_AVAILABLE_COUPON_BALANCE";
export const FAILED_PAYMENT_LAST_30_days = "FAILED_PAYMENT_LAST_30_days";
export const OPEN_ACCOUNT = "OPEN_ACCOUNT";
export const TO_BE_PAID = "TO_BE_PAID";
export const PARTIALLY_PAID = "PARTIALLY_PAID";
export const UN_PAID = "UN_PAID";
export const CURRENTLY_RECURRING = "CURRENTLY_RECURRING";
export const RECURRING_FAILED = "RECURRING_FAILED";
export const CREATE_DRIVER_PROFILE = "CREATE_DRIVER_PROFILE";
export const ENABLE_DRIVER_PROFILE = "ENABLE_DRIVER_PROFILE";
export const REFERRAL = "REFERRAL";
export const CONTACT_NAME = "CONTACT_NAME";
export const INVITATION_DATE = "INVITATION_DATE";
export const SIGNED_UP_ON = "SIGNED_UP_ON";
export const ADD_RATE = "ADD_RATE";
export const REFERRED = "REFERRED";
export const JOINED = "JOINED";
export const DELETED = "DELETED";

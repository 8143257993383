import React, {
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from "react";
import axios from "axios";
import { setLanguage } from "../utils/message";
import { LocalStorageUtil } from "../utils/LocalStorageUtil";

const SidebarContext = createContext();

export const SidebarContextPrv = ({ children }) => {
  const [sideBar, setSideBar] = useState(false);
  const [orgType, setOrgType] = useState(["FLEETORG"]);
  const [user, setUser] = useState(1);
  const [imageurl, setImageurl] = useState("");
  const [username, setUsername] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [breadCrumpsData, setbreadCrumpsData] = useState({});
  const [dialogueData, setDialogueData] = useState("");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [USCODE, setUSCODE] = useState("");
  const [CADCODE, setCADCODE] = useState("");
  const [name, setName] = useState("");
  const [language, setLang] = useState("English");
  const [isOrgNetworkConfigEnabled, setisOrgNetworkConfigEnabled] =
    useState("");

  const localLang = JSON.parse(String(LocalStorageUtil.getItem("language")));

  const openSidebar = () => {
    setSideBar(!sideBar);
    setLang(localLang);
    loadLanguages(localLang);
  };

  const loadLanguages = async (lang) => {
    const timestamp = Date.now();
    const baseUrl = "..";

    // Map of supported languages to their file names
    const languageFiles = {
      French: "french.json",
      Spanish: "Spanish.json",
      default: "language.json",
    };

    const fileName = languageFiles[lang] || languageFiles.default;
    const langUrl = `${baseUrl}/${fileName}?rand=${timestamp}`;

    try {
      const { data } = await axios.get(langUrl);

      // Batch state updates and localStorage operations
      await Promise.all([
        setLang(lang),
        setLanguage(data),
        Promise.resolve().then(() => {
          localStorage.setItem("language", lang);
          LocalStorageUtil.setItem("language", JSON.stringify(lang));
        }),
      ]);
    } catch (error) {
      console.error("Error loading language data:", error);
      throw error; // Allow caller to handle the error
    }
  };

  // const loadLanguages = async (lang) => {
  //   const random = new Date().getTime();
  //   let langUrl = "../language.json?rand=" + random;
  //   if (lang === "French") {
  //     langUrl = "../french.json?rand=" + random;
  //   } else if (lang === "Spanish") {
  //     langUrl = "../Spanish.json?rand=" + random;
  //   }
  //   try {
  //     const res = await axios.get(langUrl);
  //     Promise.resolve().then(() => {
  //       setLang(lang);
  //       setLanguage(res?.data);
  //       LocalStorageUtil.setItem("language", JSON.stringify(lang));
  //       localStorage.setItem("language", lang);
  //     });
  //   } catch (error) {
  //     console.error("Error loading language data:", error);
  //   }
  // };

  const changeUser = (orgKey) => {
    setUser(orgKey);
  };

  const changeLanguage = (lang) => {
    if (language !== lang) {
      loadLanguages(lang);
    }
  };

  const setCountryCodes = (US, CAD) => {
    setUSCODE(US);
    setCADCODE(CAD);
  };

  const changeOrgType = (orgType) => {
    setOrgType(orgType);
  };

  const changeUserName = (name) => {
    setUsername(name);
  };

  const closeDialogueWindow = () => {
    setOpenDialogue(false);
    setDialogueData("");
  };
  const openDialogueWindow = (data) => {
    setOpenDialogue(true);
    setDialogueData(data);
  };

  const switchOrg = (value) => {
    setName(value);
  };

  const memoizedValue = useMemo(
    () => ({
      sideBar,
      openSidebar,
      user,
      changeUser,
      changeUserName,
      username,
      breadCrumpsData,
      imageurl,
      setImageurl,
      setbreadCrumpsData,
      closeDialogueWindow,
      openDialogueWindow,
      dialogueData,
      openDialogue,
      setUserFirstName,
      setUserLastName,
      userFirstName,
      userLastName,
      changeOrgType,
      orgType,
      USCODE,
      CADCODE,
      setCountryCodes,
      isOrgNetworkConfigEnabled,
      setisOrgNetworkConfigEnabled,
      name,
      switchOrg,
      language,
      changeLanguage,
    }),
    [
      sideBar,
      openSidebar,
      user,
      changeUser,
      changeUserName,
      username,
      breadCrumpsData,
      imageurl,
      setImageurl,
      setbreadCrumpsData,
      openDialogue,
      setUserFirstName,
      setUserLastName,
      userFirstName,
      userLastName,
      changeOrgType,
      orgType,
      USCODE,
      CADCODE,
      setCountryCodes,
      isOrgNetworkConfigEnabled,
      setisOrgNetworkConfigEnabled,
      name,
      switchOrg,
      language,
    ]
  );

  return (
    <SidebarContext.Provider value={memoizedValue}>
      {children}
    </SidebarContext.Provider>
  );
};
// make sure use
export const useSidebarContext = () => {
  return useContext(SidebarContext);
};

import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { getLastVisitedPage } from "../utils/common";

const initstate: any = {
  selectedSection: "MAPVIEW",
  activeStep: 1,
  showBookReserve: "",
  selectedPL: {},
  selectedFleetOrg: {},
  allParkingLotLocations: [],
  counts: {
    parkingLots: "",
    activeReservations: "",
    pendingPaymants: "",
    drivers: "",
    failedPayments: "",
  },
  filterSearch: {
    duration: "",
    amenities: "",
    amenitiesList: [],
  },
  filterSearch4Reserve: {
    duration: "",
    amenities: "",
    amenitiesList: [],
  },
  reservationCreationMode: "manual",
  noOfSlotstoReserve: 0,
  listOfDetailsForReservation: [],
  bookFleetReservation: {},
  paymentIncludeRecurring:false,
  paymentOptions:{
    allCreditCard: [],
    allBank: null,
    allOpenAccount: []
  }
};
export const FinparkReserveSlice = createSlice({
  name: "finparkReserve",
  initialState: initstate,
  reducers: {
    setAllParkingLotLocations: (state, action) => {
      state.allParkingLotLocations = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
      state.showBookReserve = "";
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
      state.bookFleetReservation = {};
    },
    setShowBookReserve: (state, action) => {
      state.showBookReserve = action.payload;
      state.selectedFleetOrg = "";
      state.reservationCreationMode = "manual";
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
      state.bookFleetReservation = {};
    },
    setParkingLotCounts: (state, action) => {
      state.counts.parkingLots = action.payload;
    },
    setActiveReservationCounts: (state, action) => {
      state.counts.activeReservations = action.payload;
    },
    setDriverCounts: (state, action) => {
      state.counts.drivers = action.payload;
    },
    setPendingReservationCounts: (state, action) => {
      state.counts.pendingPaymants = action.payload;
    },
    setFailedPaymentCounts: (state, action) => {
      state.counts.failedPayments = action.payload;
    },
    setSelectedPL: (state, action) => {
      state.selectedPL = action.payload;
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
    },
    setFilterAmenities: (state, action) => {
      const searchItem = getLastVisitedPage();
      state[searchItem].amenities = action.payload;
    },
    setFilterAmenitiesList: (state, action) => {
      const searchItem = getLastVisitedPage();
      state[searchItem].amenitiesList = action.payload;
    },
    removeFilterAmenities: (state, action) => {
      const searchItem = getLastVisitedPage();
      const arr: any = state[searchItem].amenities
        ?.split(",")
        ?.filter((item: string) => item !== action.payload);

      state[searchItem].amenities = arr?.join(",");
    },
    setSelectedFleetOrg: (state, action) => {
      state.selectedFleetOrg = action.payload;
      // state.reservationCreationMode = "manual";
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
    },
    setReservationCreationMode: (state, action) => {
      state.reservationCreationMode = action.payload;
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
    },
    setBookFleetReservation: (state, action) => {
      state.bookFleetReservation = action.payload;
    },
    setPaymentIncludeRecurring: (state, action) => {
      state.paymentIncludeRecurring = action.payload;
    },
    setNoOfSlotstoReserve: (state, action) => {
      state.noOfSlotstoReserve = action.payload;
    },
    setActiveStepChange: (state, action) => {
      state.activeStep = action.payload;
    },
    addDriverOrAsset: (state, action) => {
      let obj:any = {...action.payload};
      obj.sno = state.listOfDetailsForReservation?.length +1;
      const list: any = [...state.listOfDetailsForReservation, obj]?.map((ele:any,index:number)=>{
        let item:any = {...ele,sno:(index+1)};
        return item; 
      });
      state.listOfDetailsForReservation = list;
    },
    removeDriverOrAsset: (state, action) => {

      const list: any = state.listOfDetailsForReservation?.filter(
        (ele: any) => ele.sno !== action.payload
      )?.map((ele:any,index:number)=>{
        let item:any = {...ele,sno:(index+1)};
        return item; 
      });
      state.listOfDetailsForReservation = list;
    },
    addAminities: (state, action) => {
      const searchItem = getLastVisitedPage();
      state[searchItem].amenities +=
        state[searchItem].amenities === ""
          ? action.payload
          : "," + action.payload;
    },
    addDuration: (state, action) => {
      const searchItem = getLastVisitedPage();
      state[searchItem].duration = action.payload;
    },
    removeDuration: (state, action) => {
      const searchItem = getLastVisitedPage();
      state[searchItem].duration = "";
    },
    clearAllAminitiesDuration:(state)=>{
      state.filterSearch.duration = "";
      state.filterSearch.amenities = "";
      state.filterSearch.amenitiesList = "";
      state.filterSearch4Reserve.duration = "";
      state.filterSearch4Reserve.amenities = "";
      state.filterSearch4Reserve.amenitiesList = "";
    },
    setAllCreditCard:(state, action)=>{
      state.paymentOptions.allCreditCard = action.payload;
    },
    setAllBank:(state, action)=>{
      state.paymentOptions.allBank = action.payload;
    },
    setAllOpenAccount:(state, action)=>{
      state.paymentOptions.allOpenAccount = action.payload;
    }
  },
});

export const {
  setAllParkingLotLocations,
  setSelectedSection,
  setParkingLotCounts,
  setActiveReservationCounts,
  setDriverCounts,
  setPendingReservationCounts,
  setFailedPaymentCounts,
  setSelectedPL,
  setSelectedFleetOrg,
  setReservationCreationMode,
  addDriverOrAsset,
  removeDriverOrAsset,
  setShowBookReserve,
  setBookFleetReservation,
  setNoOfSlotstoReserve,
  removeFilterAmenities,
  setFilterAmenitiesList,
  setFilterAmenities,
  setActiveStepChange,
  addAminities,
  addDuration,
  removeDuration,
  clearAllAminitiesDuration,
  setPaymentIncludeRecurring,
  setAllCreditCard,
  setAllBank,
  setAllOpenAccount
} = FinparkReserveSlice.actions;

export default FinparkReserveSlice.reducer;

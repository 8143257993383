import { makeStyles } from "@mui/styles";
import { LocalStorageUtil } from "./LocalStorageUtil";
import dayjs from "dayjs";
import { getMessageText } from "./message";
import * as LangConst from "./constant/LangConst";

export const currencyFormatter = (currency: any, sign: string) => {
  if (currency === null || currency === undefined) return "";
  var sansDec = Number(currency).toFixed(2);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return sign + `${formatted}`;
};

export const strDateToServerDate = (strDate: any) => {
  let tempDate = strDate;
  try {
    tempDate = `${strDate.split("T")[0]}`;
  } catch (e) {
    tempDate = strDate;
  }

  return tempDate || "";
};
export const strDateToServerDatetime = (strDate: any) => {
  let tempDate = strDate;
  try {
    tempDate = `${strDate.split("T")[0]} 00:00:00`;
  } catch (e) {
    tempDate = strDate;
  }

  return tempDate;
};

export const strDateTimeDate = (dt: any) => {
  if (dt) {
    let dateTime: any = "";
    if (dt.indexOf("T") !== -1) {
      dateTime = dt.split("T");
    } else if (dt.indexOf(" ") !== -1) {
      dateTime = dt.split("T");
    } else {
      dateTime = dt;
    }
    let arr: any = [];
    if (Array.isArray(dateTime)) {
      arr = dateTime[0].split("-");
    } else {
      arr = dateTime.split("-");
    }

    let date = new Date(Number(arr[0]), Number(arr[1] - 1), Number(arr[2])); //this.getServerDate(dt[0]);//
    if (date) {
      let strMonth: string = (
        (date.getMonth() + 1).toString().length === 1
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      ) as string;
      let strDate: string = (
        date.getDate().toString().length === 1
          ? "0" + date.getDate()
          : date.getDate()
      ) as string;
      let strYear: string = date.getFullYear().toString() as string;
      return strMonth + "/" + strDate + "/" + strYear;
    }
  }

  return "";
};

export const cardFormatter = (cardLastFour: any) => {
  if (cardLastFour === null || cardLastFour === undefined) return "";

  return `...XXXX ${cardLastFour}`;
};
export const numberFormatField = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",

      margin: 0,
    },

    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",

      margin: 0,
    },
  },
});

export const isDurationAllowForRecurring = (item: string) => {
  // const allowfor: string[] = ["6 hrs", "24 hrs", "Weekly", "Monthly", "Yearly"];
  const allowfor: string[] = [ "24 hrs", "Weekly", "Monthly", "Yearly"];
  return allowfor.includes(item);
};

export const getSelectedDuration = (newDate: any, selectedDuration: any) => {
  let edDate = newDate.add(0, "day");
  if (selectedDuration) {
    switch (selectedDuration) {
      case "Monthly":
        edDate = newDate.add(30, "day");
        break;
      case "24 hrs":
        edDate = newDate.add(1, "day");
        break;
      case "36 hrs":
        edDate = newDate.add(36, "hour");
        break;
      case "12 hrs":
        edDate = newDate.add(12, "hour");
        break;
      case "6 hrs":
        edDate = newDate.add(6, "hour");
        break;
      case "Weekly":
        edDate = newDate.add(7, "day");
        break;
      case "Yearly":
        edDate = newDate.add(365, "day");
        break;
      default:
        edDate = newDate.add(0, "day");
    }
  }
  return edDate;
};

export const showData = (
  params: any,
  path: string,
  type: string,
  navigate: any
) => {
  return (
    <div
      style={{
        cursor: "pointer",
        textDecoration: "underline",
        fontWeight: "500",
        color: "#001C81",
      }}
      onClick={() =>
        NavigatePage(params.data.reservationId, path, type, navigate)
      }
    >
      {params.value}
    </div>
  );
};

export const NavigatePage = (path: any, id: any, type: any, navigate: any) => {
  LocalStorageUtil.setItem(path, id);
  if (type === "_blank") {
    window.open(`${window.location.host}/${path}/${id}`, type);
  } else {
    return navigate(`/${path}/${id}`);
  }
};

export function ExternalScript(urlOfTheLibrary: string) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

let gEndData: string = "";

export const calculateRecurringEndDate = (
  month: any,
  startDate: any,
  duration: any
) => {
  const noOfDays =
    String(duration).trim().toLowerCase() === "24 hrs"
      ? 1
      : String(duration).trim().toLowerCase() === "6 hrs"
      ? 6
      : String(duration).trim().toLowerCase() === "weekly"
      ? 7
      : String(duration).trim().toLowerCase() === "monthly"
      ? 30
      : String(duration).trim().toLowerCase() === "yearly"
      ? 365
      : 0;

  try {
    const parsedMonth =
      (parseInt(month, 10) + (noOfDays === 6 ? 0 : 0)) * noOfDays;
    if (!isNaN(parsedMonth) && startDate) {
      const endDate =
        noOfDays === 6
          ? startDate.add(parsedMonth, "hour").toDate()
          : startDate.add(parsedMonth, "days").toDate();
      gEndData = endDate;
      return dayjs(endDate);
    }
  } catch (e) {}
};

export const dateComparator = (
  date1: any,
  date2: any,
  format: any = "MM/DD/YYYY HH:mm"
) => {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }
  const date1Number: any = dayjs(date1, format);
  const date2Number: any = dayjs(date2, format);
  return date1Number - date2Number;
};

export const numberComparator = (data1: any, data2: any) => {
  try {
    if (data1 === null && data2 === null) {
      return 0;
    }
    if (data1 === null) {
      return -1;
    }
    if (data2 === null) {
      return 1;
    }
    return data1 - data2;
  } catch (e) {
    console.log(e);
  }
};

//Duration converter
const durationConvert = (data: any) => {
  try {
    switch (String(data).trim().toLowerCase()) {
      case "6 hrs":
        return 1;
      case "12 hrs":
        return 2;
      case "24 hrs":
        return 3;
      case "36 hrs":
        return 4;
      case "weekly":
        return 5;
      case "monthly":
        return 6;
      case "yearly":
        return 7;
      default:
        return 0;
    }
  } catch (e) {
    console.log(e);
  }
};

export const durationComparator = (data1: any, data2: any) => {
  try {
    const sDate = durationConvert(data1) || 0;
    const eDate = durationConvert(data2) || 0;
    if (sDate === null && eDate === null) {
      return 0;
    }
    if (sDate === null) {
      return -1;
    }
    if (eDate === null) {
      return 1;
    }
    return sDate - eDate;
  } catch (e) {
    console.log(e);
  }
};

export const makeAsPaid = (list: any) => {
  const paidItems = [
    1617, 1471, 1481, 1621, 1483, 1623, 1029, 1459, 868, 1327, 1077, 1470, 1030,
    905, 1027, 1472, 1565, 1073, 1651, 1072, 1482, 1622, 1352, 1308, 1437, 1078,
    1347, 916, 1266, 1471, 1265, 1617, 1263, 1195, 1079, 1026, 1170, 1264, 1262,
  ];
  if (list?.length > 0) {
    const includePaidList = list?.map((item: any) => {
      let obj: any = { ...item };
      let consolidatedStatementDTOList: any = obj.consolidatedStatementDTOList
        ? [...obj.consolidatedStatementDTOList]
        : [];
      const includeconsolidatedStatementDTOList =
        consolidatedStatementDTOList?.map((item: any) => {
          let ele = { ...item };
          if (paidItems.includes(Number(ele.id))) {
            ele.status = "Paid";
          }
          return ele;
        });
      obj.consolidatedStatementDTOList = includeconsolidatedStatementDTOList;
      return obj;
    });
    return includePaidList;
  }

  return [];
};

export const getChipStyles = (status: string) => {
  switch (status) {
    case "ACTIVE":
    case "Active":
    case "active":
    case "BOOKED":
    case "Booked":
    case "UPCOMING":
    case "Upcoming":
    case "REFUNDED":
    case "Refunded":
    case "COMPLETED":
    case "Completed":
    case "CANCELLED":
    case "Cancelled":
    case "PAYMENT_REFUNDED":
    case "PAYMENT REFUNDED":
    case "Payment Refunded":
    case "PAYMENT_SUCCESS":
    case "Payment Success":
    case "REFERRED":
    case "Referred":
    case "JOINED":
    case "Joined":
      return { color: "#03d87f", backgroundColor: "rgba(3, 216, 127, 0.15)" };
    case "FAILED":
    case "Failed":
    case "EXPIRED":
    case "Expired":
    case "Removed":
    case "REMOVED":
    case "Booking Failed":
    case "BOOKING_FAILED":
    case "PAYMENT_FAILED":
    case "Payment Failed":
      return { color: "#f5325c", backgroundColor: "rgba(245, 50, 92, 0.15)" };
    case "Not Verified":
    case "NOT VERIFIED":
    case "NOT_VERIFIED":
    case "PENDING_PAYMENT":
    case "PENDING PAYMENT":
    case "Pending Payment":
    case "PAYMENT_INITIATED":
    case "PAYMENT_PENDING":
    case "Payment Pending":
    case "Payment Initiated":
    case "Pending Approval":
    case "PENDING APPROVAL":
      return { color: "#e5c35b", backgroundColor: "#fffed1" };
    default:
      return { color: "#9ba7ca", backgroundColor: "rgba(155, 167, 202, 0.15)" };
  }
};

export const getMessageTextForStatus = (status: string) => {
  const normalizedStatus = String(status).toUpperCase();

  const statusMessages: Record<string, string> = {
    ACTIVE: getMessageText(LangConst.ACTIVE, "Active"),
    INACTIVE: getMessageText(LangConst.IN_ACTIVE, "Inactive"),
    COMPLETED: getMessageText(LangConst.COMPLETED, "Completed"),
    CANCELLED: getMessageText(LangConst.CANCELLED, "Cancelled"),
    BOOKED: getMessageText(LangConst.BOOKED, "Booked"),
    UPCOMING: getMessageText(LangConst.UPCOMING, "Upcoming"),
    FAILED: getMessageText(LangConst.FAILED, "Failed"),
    EXPIRED: getMessageText(LangConst.EXPIRED, "Expired"),
    REMOVED: getMessageText(LangConst.REMOVED, "Removed"),
    INVITED: getMessageText(LangConst.INVITED, "Invited"),
    PENDING: getMessageText(LangConst.PENDING, "Pending"),
    REFUNDED: getMessageText(LangConst.REFUNDED, "Refunded"),
    REFERRED: getMessageText(LangConst.REFERRED, "Referred"),
    JOINED: getMessageText(LangConst.JOINED, "Joined"),
    DELETED: getMessageText(LangConst.DELETED, "Deleted"),
    UN_PAID: getMessageText(LangConst.UN_PAID, "Unpaid"),
    "TO BE PAID": getMessageText(LangConst.TO_BE_PAID, "To be Paid"),
    "PARTIALLY PAID": getMessageText(
      LangConst.PARTIALLY_PAID,
      "Partially Paid"
    ),
    "CURRENTLY RECURRING": getMessageText(
      LangConst.CURRENTLY_RECURRING,
      "Currently Recurring"
    ),
    CURRENTLY_RECURRING: getMessageText(
      LangConst.CURRENTLY_RECURRING,
      "Currently Recurring"
    ),
    "RECURRING FAILED": getMessageText(
      LangConst.RECURRING_FAILED,
      "Recurring Failed"
    ),
    RECURRING_FAILED: getMessageText(
      LangConst.RECURRING_FAILED,
      "Recurring Failed"
    ),
    "PAYMENT REFUNDED": getMessageText(
      LangConst.PAYMENT_REFUNDED,
      "Payment Refunded"
    ),
    PAYMENT_REFUNDED: getMessageText(
      LangConst.PAYMENT_REFUNDED,
      "Payment Refunded"
    ),
    "PAYMENT SUCCESS": getMessageText(
      LangConst.PAYMENT_SUCCESS,
      "Payment Success"
    ),
    PAYMENT_SUCCESS: getMessageText(
      LangConst.PAYMENT_SUCCESS,
      "Payment Success"
    ),
    "PAYMENT FAILED": getMessageText(
      LangConst.PAYMENT_FAILED,
      "Payment Failed"
    ),
    PAYMENT_FAILED: getMessageText(LangConst.PAYMENT_FAILED, "Payment Failed"),
    "BOOKING FAILED": getMessageText(
      LangConst.BOOKING_FAILED,
      "Booking Failed"
    ),
    BOOKING_FAILED: getMessageText(LangConst.BOOKING_FAILED, "Booking Failed"),
    "PENDING PAYMENT": getMessageText(
      LangConst.PENDING_PAYMENT,
      "Pending Payment"
    ),
    PENDING_PAYMENT: getMessageText(
      LangConst.PENDING_PAYMENT,
      "Pending Payment"
    ),
    "PAYMENT INITIATED": getMessageText(
      LangConst.PAYMENT_INITIATED,
      "Payment Initiated"
    ),
    PAYMENT_INITIATED: getMessageText(
      LangConst.PAYMENT_INITIATED,
      "Payment Initiated"
    ),
    "PAYMENT PENDING": getMessageText(
      LangConst.PAYMENT_PENDING,
      "Payment Pending"
    ),
    PAYMENT_PENDING: getMessageText(
      LangConst.PAYMENT_PENDING,
      "Payment Pending"
    ),
    "NOT VERIFIED": getMessageText(LangConst.NOT_VERIFIED, "Not Verified"),
    NOT_VERIFIED: getMessageText(LangConst.NOT_VERIFIED, "Not Verified"),
    "PENDING APPROVAL": getMessageText(
      LangConst.PENDING_APPROVAL,
      "Pending Approval"
    ),
    PENDING_APPROVAL: getMessageText(
      LangConst.PENDING_APPROVAL,
      "Pending Approval"
    ),
  };

  return statusMessages[normalizedStatus] || status;
};

export const getLastVisitedPage = () => {
  let fromLoc = LocalStorageUtil.getItem("finparkLastViewedPage");
  try {
    if (fromLoc.indexOf("reserve") != -1) return "filterSearch4Reserve";
    else return "filterSearch";
  } catch (e) {
    return "filterSearch";
  }
};

export const logOutSession = () => {
  localStorage.setItem("language", "English");
  LocalStorageUtil.setItem("finparkLastViewedPage", "");
  LocalStorageUtil.setItem("token", "");
  sessionStorage.clear();
  // reDirectURLTo(navigate, "/");
  localStorage.removeItem("lastLoginKey");
  localStorage.setItem(
    "language",
    JSON.parse(String(LocalStorageUtil.getItem("language")))
  );
};
